import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core';
import {CoreModule} from '../core/core.module';
import {VenueSectionComponent} from './components/venue-section/venue-section.component';
import {OrderTableComponent} from './components/order-table/order-table.component';
import {PricePlanTableComponent} from './components/price-plan-table/price-plan-table.component';
import {OrderListSimpleComponent} from './components/order-list-simple/order-list-simple.component';
import {OrderDistributionSettingComponent} from './components/order-distribution-setting/order-distribution-setting.component';
import {ListPickerComponent} from './components/list-picker/list-picker.component';
import {MoveOrderSeatmapComponent} from './components/move-order-seatmap/move-order-seatmap.component';
import {PaymentTableComponent} from './components/payment-table/payment-table.component';
import {OrderCapacityListComponent} from './components/order-capacity-list/order-capacity-list.component';
import {CustomFieldSettingCardComponent} from './components/custom-field-setting-card/custom-field-setting-card.component';
import {SeatmapComponent} from './components/seatmap/seatmap.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {FilePickerComponent} from './components/file-picker/file-picker.component';
import {SearchFilterContainerComponent} from './components/search-filter-container/search-filter-container.component';
import {OrderDetailSidebarComponent} from './components/order-detail-sidebar/order-detail-sidebar.component';
import {ConsentDetailComponent} from './components/consent-detail/consent-detail.component';
import {EventAutocompleteComponent} from './components/event-autocomplete/event-autocomplete.component';
import {MoveOrderSidebarComponent} from './components/move-order-sidebar/move-order-sidebar.component';
import {SearchFilterComponent} from './components/search-filter/search-filter.component';
import {EventTicketTableFilterComponent} from '../events/components/event-ticket-table-filter/event-ticket-table-filter.component';
import {EventTicketListComponent} from '../events/components/event-ticket-list/event-ticket-list.component';
import {CustomerProductsComponent} from '../customers/components/customer-products/customer-products.component';
import {CustomerGuestManagerListComponent} from '../customers/components/customer-guest-manager-list/customer-guest-manager-list.component';
import {OrderSummaryComponent} from './components/order-summary/order-summary.component';
import {BorderPickerComponent} from './components/border-picker/border-picker.component';
import {SearchComponent} from './components/search/search.component';
import {CustomerSettingsComponent} from '../customers/components/customer-settings/customer-settings.component';
import {CampaignDetailComponent} from './components/campaign/campaign-detail/campaign-detail.component';
import {AnalyticsDashboardComponent} from './components/analytics/analytics-dashboard/analytics-dashboard.component';
import {CustomerFormComponent} from './components/customer-form/customer-form.component';
import {StatisticsComponent} from './components/statistics/statistics.component';
import {AnalyticsChartComponent} from './components/analytics/analytics-chart/analytics-chart.component';
import {ActivityLogComponent} from './components/activity-log/activity-log.component';
import {ActivityLogV2Component} from './components/activity-log-v2/activity-log-v2.component';
import {AnalyticsDashboardItemComponent} from './components/analytics/analytics-dashboard-item/analytics-dashboard-item.component';
import {AnalyticsDashboardItemEditorComponent} from './components/analytics/analytics-dashboard-item-editor/analytics-dashboard-item-editor.component';
import {AnalyticsDashboardItemSettingComponent} from './components/analytics/analytics-dashboard-item-setting/analytics-dashboard-item-setting.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {OrderDeliveryComponent} from './components/order-delivery/order-delivery/order-delivery.component';
import {SettingCheckboxPickerComponent} from './components/setting-checkbox-picker/setting-checkbox-picker.component';
import {StatisticsGroupsComponent} from './components/statistics-groups/statistics-groups.component';
import {EventAnalyticsVenueSectionTableComponent} from './components/event-analytics-venue-section-table/event-analytics-venue-section-table.component';
import {EventSaleSettingComponent} from './components/event-sale-setting/event-sale-setting.component';
import {VenueFormComponent} from './components/venue-form/venue-form.component';
import {ActionFooterComponent} from './components/action-footer/action-footer.component';
import {DownloadManagerComponent} from './components/download-manager/download-manager.component';
import {OrderComponent} from './components/order/order.component';
import {MenuComponent} from './components/menu/menu.component';
import {MenuSearchResultsComponent} from './components/menu-search-results/menu-search-results.component';
import {PageWrapperComponent} from './components/page-wrapper/page-wrapper.component';
import {SubscriptionTypeService} from './services/subscription-type-service/subscription-type.service';
import {PriceService} from './services/price-service/price.service';
import {EventCategoryService} from './services/event-category-service/event-category.service';
import {SubscriptionService} from './services/subscription-service/subscription.service';
import {SubscriptionTypePriceService} from './services/subscription-type-price-service/subscription-type.service';
import {ShopService} from './services/shop-service/shop.service';
import {TicketTypeService} from './services/ticket-service/ticket.service';
import {VenueManagementService} from './services/venue-service/venue-management.service';
import {EventCategoryManagementService} from './services/event-category-service/event-category-management.service';
import {PaymentService} from './services/payment-service/payment.service';
import {EventManagementService} from './services/event-service/event-management.service';
import {WhereToService} from './services/platform-service/where-to.service';
import {OrderDeliveryService} from './services/order-delivery-service/order-delivery.service';
import {EventService} from './services/event-service/event.service';
import {NotificationService} from './services/notification-service/notification.service';
import {VenueService} from './services/venue-service/venue.service';
import {ExportService} from './services/export-service/export.service';
import {OrderService} from './services/order-service/order.service';
import {CustomReportService} from './services/report-service/custom-report.service';
import {ResourceService} from './services/resource-service/resource.service';
import {CustomerService} from './services/customer-service/customer.service';
import {DialogConfirmationComponent} from './components/dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogBlacklistComponent} from './components/dialogs/dialog-blacklist/dialog-blacklist.component';
import {DialogAlertComponent} from './components/dialogs/dialog-alert/dialog-alert.component';
import {DialogCreateEventCategoryComponent} from './components/dialogs/dialog-create-event-category/dialog-create-event-category.component';
import {DialogEditPrinterComponent} from './components/dialogs/dialog-edit-printer/dialog-edit-printer.component';
import {DialogFilePickerComponent} from './components/dialogs/dialog-file-picker/dialog-file-picker.component';
import {DialogEditCustomerComponent} from './components/dialogs/dialog-edit-customer/dialog-edit-customer.component';
import {DialogRingSettingsComponent} from './components/dialogs/dialog-ring-settings/dialog-ring-settings.component';
import {DialogExportCustomerListComponent} from './components/dialogs/dialog-export-customer-list/dialog-export-customer-list.component';
import {DialogVenueSectionSeatSettingComponent} from './components/dialogs/dialog-venue-section-seat-setting/dialog-venue-section-seat-setting.component';
import {DialogOrderAddDiscountComponent} from './components/dialogs/dialog-order-add-discount/dialog-order-add-discount.component';
import {DialogCostItemAgreementComponent} from './components/dialogs/dialog-cost-item-agreement/dialog-cost-item-agreement.component';
import {PaymentListComponent} from './components/payment-list/payment-list.component';
import {DialogComponent} from './components/dialogs/dialog/dialog.component';
import {SettingsComponent} from './components/settings/settings.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {MoveRequestSidebarComponent} from './components/move-request-sidebar/move-request-sidebar.component';
import {SearchFilterBooleanComponent} from './components/search-filter-boolean/search-filter-boolean.component';
import {ListPreviewComponent} from './components/list-preview/list-preview.component';
import {EmptyStateComponent} from './components/empty-state/empty-state.component';
import {StepperPageComponent} from './components/stepper-page/stepper-page.component';
import {StepperStepComponent} from './components/stepper-step/stepper-step.component';
import {BannerComponent} from './components/banner/banner.component';
import {VenueSectionMasterDetailComponent} from './components/venue-section-master-detail/venue-section-master-detail.component';
import {SeatMapLegendItemComponent} from './components/seat-map-legend-item/seat-map-legend-item.component';
import {ServiceInjector} from './services/service-injector.service';
import {BlockadeActionFooterComponent} from './components/blockade-action-footer/blockade-action-footer.component';
import {SeatMapTooltipComponent} from './components/seat-map-tooltip/seat-map-tooltip.component';
import {VenueSectionListItemComponent} from './components/venue-section-list-item/venue-section-list-item.component';
import {DialogExportVisitorsComponent} from './components/dialogs/dialog-export-visitors/dialog-export-visitors.component';
import {ConsentDetailsComponent} from './components/order/consent-details/consent-details.component';
import {TableComponent} from './components/table/table.component';
import {CardComponent} from './components/card/card.component';
import {OrderPaymentTableComponent} from './components/order-payment-table/order-payment-table.component';
import {OrderDeliveryTableComponent} from './components/order-delivery-table/order-delivery-table.component';
import {ListComponent} from './components/aurora/list/list.component';
import {StartEndDatePipe} from './pipes/start-end-date.pipe';
import {BasketOrderTableComponent} from './components/basket/basket-order-table/basket-order-table.component';
import {TableCheckboxesDirective} from './directives/table-checkboxes.directive';
import {StartEndDateTimePipe} from './pipes/start-end-date-time.pipe';
import {ProgressNotificationComponent} from './components/progress-notification/progress-notification.component';
import {CampaignSelectorComponent} from './components/campaign/campaign-selector/campaign-selector.component';
import {ExternalBarcodeService} from './services/external-barcode-service/external-barcode.service';
import {ReportExportListIconComponent} from './components/report-export-list-icon/report-export-list-icon.component';
import {CustomerOrderService} from './services/customer-order-service/customer-order.service';
import {SimpleTableComponent} from './components/simple-table/simple-table.component';
import {CostItemsComponent} from './components/cost-items/cost-items.component';
import {WidgetComponent} from './components/widget/widget.component';
import {CopyButtonComponent} from './copy-button/copy-button.component';
import {CustomerListService} from './services/customer-service/customer-list.service';
import {SaleStatusSettingComponent} from './components/sale-status-setting/sale-status-setting.component';

@NgModule({
    declarations: [
        ActivityLogComponent,
        ActivityLogV2Component,
        AnalyticsChartComponent,
        AnalyticsDashboardComponent,
        AnalyticsDashboardItemComponent,
        AnalyticsDashboardItemEditorComponent,
        AnalyticsDashboardItemSettingComponent,
        BorderPickerComponent,
        CampaignDetailComponent,
        ConsentDetailComponent,
        CustomFieldSettingCardComponent,
        CustomerFormComponent,
        CustomerGuestManagerListComponent,
        CustomerProductsComponent,
        CustomerSettingsComponent,
        EventAutocompleteComponent,
        EventTicketListComponent,
        EventTicketTableFilterComponent,
        FilePickerComponent,
        ListPickerComponent,
        MoveOrderSeatmapComponent,
        MoveOrderSidebarComponent,
        OrderCapacityListComponent,
        OrderDetailSidebarComponent,
        OrderDistributionSettingComponent,
        OrderListSimpleComponent,
        OrderSummaryComponent,
        OrderTableComponent,
        PaginationComponent,
        PaymentTableComponent,
        PricePlanTableComponent,
        SearchComponent,
        SearchFilterComponent,
        SearchFilterBooleanComponent,
        SearchFilterContainerComponent,
        SeatmapComponent,
        StatisticsComponent,
        VenueSectionComponent,
        OrderDeliveryComponent,
        SettingCheckboxPickerComponent,
        StatisticsGroupsComponent,
        EventAnalyticsVenueSectionTableComponent,
        EventSaleSettingComponent,
        VenueFormComponent,
        OrderComponent,
        ActionFooterComponent,
        MenuComponent,
        MenuSearchResultsComponent,
        PageWrapperComponent,
        DownloadManagerComponent,
        DialogConfirmationComponent,
        DialogBlacklistComponent,
        DialogAlertComponent,
        DialogCreateEventCategoryComponent,
        DialogEditPrinterComponent,
        DialogFilePickerComponent,
        DialogEditCustomerComponent,
        DialogRingSettingsComponent,
        DialogExportCustomerListComponent,
        DialogVenueSectionSeatSettingComponent,
        DialogOrderAddDiscountComponent,
        DialogCostItemAgreementComponent,
        PaymentListComponent,
        DialogComponent,
        SearchFilterBooleanComponent,
        StepperComponent,
        MoveRequestSidebarComponent,
        ListPreviewComponent,
        EmptyStateComponent,
        StepperPageComponent,
        BannerComponent,
        VenueSectionMasterDetailComponent,
        SeatMapLegendItemComponent,
        BlockadeActionFooterComponent,
        SeatMapTooltipComponent,
        StepperStepComponent,
        VenueSectionListItemComponent,
        DialogExportVisitorsComponent,
        ConsentDetailsComponent,
        TableComponent,
        CardComponent,
        OrderPaymentTableComponent,
        OrderDeliveryTableComponent,
        ListComponent,
        StartEndDatePipe,
        StartEndDateTimePipe,
        BasketOrderTableComponent,
        TableCheckboxesDirective,
        ProgressNotificationComponent,
        CampaignSelectorComponent,
        CostItemsComponent,
        ReportExportListIconComponent,
        SimpleTableComponent,
        WidgetComponent,
        CopyButtonComponent,
        SaleStatusSettingComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        HighchartsChartModule
    ],
    exports: [
        ActivityLogComponent,
        ActivityLogV2Component,
        AnalyticsChartComponent,
        AnalyticsDashboardComponent,
        AnalyticsDashboardItemComponent,
        AnalyticsDashboardItemEditorComponent,
        AnalyticsDashboardItemSettingComponent,
        BorderPickerComponent,
        CampaignDetailComponent,
        ConsentDetailComponent,
        CustomFieldSettingCardComponent,
        CustomerFormComponent,
        CustomerGuestManagerListComponent,
        CustomerProductsComponent,
        CustomerSettingsComponent,
        EventAutocompleteComponent,
        EventTicketListComponent,
        EventTicketTableFilterComponent,
        FilePickerComponent,
        ListPickerComponent,
        MoveOrderSeatmapComponent,
        MoveOrderSidebarComponent,
        OrderCapacityListComponent,
        OrderDetailSidebarComponent,
        OrderDistributionSettingComponent,
        OrderListSimpleComponent,
        OrderSummaryComponent,
        OrderTableComponent,
        PaginationComponent,
        PaymentTableComponent,
        PricePlanTableComponent,
        SearchComponent,
        SearchFilterComponent,
        SearchFilterBooleanComponent,
        SearchFilterContainerComponent,
        SeatmapComponent,
        StatisticsComponent,
        VenueSectionComponent,
        OrderDeliveryComponent,
        SettingCheckboxPickerComponent,
        StatisticsGroupsComponent,
        EventAnalyticsVenueSectionTableComponent,
        EventSaleSettingComponent,
        VenueFormComponent,
        OrderComponent,
        ActionFooterComponent,
        MenuComponent,
        MenuSearchResultsComponent,
        PageWrapperComponent,
        DownloadManagerComponent,
        DialogComponent,
        SettingsComponent,
        StepperComponent,
        EmptyStateComponent,
        BannerComponent,
        StepperStepComponent,
        VenueSectionMasterDetailComponent,
        StepperPageComponent,
        TableComponent,
        CardComponent,
        OrderPaymentTableComponent,
        OrderDeliveryTableComponent,
        ListComponent,
        StartEndDatePipe,
        StartEndDateTimePipe,
        TableCheckboxesDirective,
        CampaignSelectorComponent,
        CostItemsComponent,
        ReportExportListIconComponent,
        SimpleTableComponent,
        WidgetComponent,
        CopyButtonComponent,
        SaleStatusSettingComponent
    ],
    providers: [
        TicketTypeService,
        ResourceService,
        PriceService,
        EventService,
        VenueService,
        OrderService,
        OrderDeliveryService,
        PaymentService,
        NotificationService,
        VenueManagementService,
        EventManagementService,
        EventCategoryManagementService,
        EventCategoryService,
        SubscriptionService,
        SubscriptionTypeService,
        SubscriptionTypePriceService,
        ShopService,
        ExportService,
        WhereToService,
        CustomReportService,
        CustomerService,
        ExternalBarcodeService,
        CustomerOrderService,
        CustomerListService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    constructor(injector: Injector) {
        ServiceInjector.injector = injector;
    }
}
