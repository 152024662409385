.m-t-5 {
    margin-top: 5px !important;
}

.icon-size {
    width: 24px;
    height: 24px;
}

span.error-red {
    color: var(--cl-error-color);
}

cm-text-editor + .cm-flag {
    position: absolute;
    top: 5px;
    right: 5px;
}
